import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { Layout, Map } from '../components'
import { GOOGLE_MAPS_KEY } from '../constants'
import { mapTheme } from '../data/map'

import { container, padding, content, pageTitle, bgIcon, menuPadding } from '../styles/global';
import { media } from '../styles/utils';

const VenueContact = ({ pageContext, ...props }) => {
	const data = JSON.parse(pageContext.acf_json)

	const renderContact = () => {
		return (
			<Text
				dangerouslySetInnerHTML={{ __html: data.long_description }}
			/>
		)
	}

	const renderLocation = () => {
		const mapProps = {
			defaultZoom: 15,
			defaultCenter: {
				lat: parseFloat(-37.80810478670861),
				lng: parseFloat(144.9699627375954),
			},
			bootstrapURLKeys: {
				key: GOOGLE_MAPS_KEY,
			},
			options: {
				styles: mapTheme,
			},
		}

		const markerProps = {
			lat: parseFloat(-37.80810478670861),
			lng: parseFloat(144.9699627375954),
			text: "Pepe's Italian",
		}

		return (
			<MapWrapper>
				<GoogleMapReact {...mapProps}>
					<Marker {...markerProps} />
				</GoogleMapReact>
			</MapWrapper>
		)
	}

	return (
		<Layout
			meta={data && data.seo}
			menu={pageContext.menu}
		>
			<Container>
				<Heading>{pageContext.sectionTitle}</Heading>

				{renderLocation()}

				<Content>
					{renderContact()}
				</Content>
			</Container>
		</Layout>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``

// Layout

const Container = styled.div`
	${container}
	${padding}
	${menuPadding}
	flex-direction: column;
	align-items: center;
	flex: 1;

	> ${Heading} {
		${pageTitle}
	}
	background-color: #F1BFB2;
	background:linear-gradient(0deg, #f1bfb294, #f1bfb294), url(https://admin.pepesitalian.com.au/wp-content/uploads/2020/08/Pepes-Illustration-Duotone-scaled-e1598509324730.jpg);
    background-repeat: no-repeat;
    background-size: cover;
`

const Content = styled.div`
	${content}
`

// Contact Info

const Text = styled.div`
    font-size: 22px;
    line-height: 26px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;

    sup {
        font-size: 14px;
        line-height: 0;
        transform: translateY(3px);
        display: inline-block;
	}

	${media.phone`
		p, a {
			font-size: 16px;
			line-height: 20px;
		}

		sup {
			font-size: 12px;
			transform: translateY(4px);
		}
	`}
`

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 128px;

  ${Heading} {
    ${pageTitle}
    margin: 48px 0 40px;
  }

  ${Text} {
    margin-top: 52px;
  }

  ${media.phone`
		padding-top: 48px;

		${Text} {
			margin-top: 40px;
		}
	`}
`

const MapWrapper = styled.div`
  height: 500px;
  width: 100%;
  max-width: 940px;
	margin-bottom: 22px;

  ${media.phone`
		margin-top: 32px;
		height: 220px;
	`}
`

const Marker = styled.div`
  background-image: url(${require('../assets/icons/map-marker.svg')});
  width: 19px;
  height: 33px;
  ${bgIcon}
`

const Socials = styled.div`
    text-align: center;
    font-size: 16px;
`



export default VenueContact

